<template>
  <div id="timeline-container" :class="{ scrollable: nodeLineWidth }">
    <div class="timelineBg">
      <div
          v-for="(node, index) in nodes"
          :key="index"
          class="timeline-item"
          :style="nodeLineWidth ? { width: nodeLineWidth + 'px' } : { flex: `1 0 ${100 / nodes.length}%` }, setTimelineItem(node)"
      >
        <div class="timeline-text top" v-if="node.topText">{{ node.topText }}</div>
        <div class="timeline-wrapper">
          <div
              class="timeline-line"
              :class="getLineClassLeft(index)"
              :style="getLineStyle(index, 'left',node)"
          ></div>
          <div
              :class="['timeline-node', getNodeClass(index)]"
              @click="toggleNode(index)"
              :style="getNodeStyle(index)"
          ></div>
          <div
              class="timeline-line"
              :class="getLineClassRight(index)"
              :style="getLineStyle(index, 'right',node)"
          ></div> 
        </div>
        <!-- <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;"> 
          <div style="width: 110px; display: block;justify-content: flex-end;align-items: center;">
            <div class="timeline-text bottom" style="text-align: right;" v-if="node.bottomText">{{ node.bottomText }}</div>
            <div class="timeline-text timeline-text-height" style="text-align: right;" v-if="node.bottomText">{{ node.titleText }}</div>
          </div>
          <div style="width: 95px;"></div>
        </div> -->
          <div style="position: absolute;top: 15px;width: 165px;left: -55px;">
            <div class="timeline-text bottom" style="text-align: right;" v-if="node.bottomText">{{ node.bottomText }}</div>
            <div class="timeline-text timeline-text-height" style="text-align: right;" v-if="node.bottomText">{{ node.titleText }}</div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimelineComponent',
  props: {
    nodes: {
      type: Array,
      required: true
    },
    activationRule: {
      type: String,
      required: true,
      default: 'both', // 默认不设置宽度
      validator: value => ['left', 'right', 'both'].includes(value)
    },
    nodeLineWidth: {
      type: Number,
      default: null // 默认不设置宽度
    },
    activeNodeStyle: {
      type: Object,
      default: () => ({backgroundColor: '#4A86E4', width: '15px', height: '15px', border:'1px solid #fff'})
    },
    inactiveNodeStyle: {
      type: Object,
      default: () => ({backgroundColor: '#fff', width: '15px', height: '15px', border:'1px solid #4A86E4'})
    },
    activeLineStyle: {
      type: Object,
      default: () => ({backgroundColor: '#4A86E4', height: '4px'})
    },
    inactiveLineStyle: {
      type: Object,
      default: () => ({backgroundColor: '#d3d3d3', height: '4px'})
    }
  },
  methods: {
    getNodeClass(index) {
      return this.nodes[index].active ? 'active' : '';
    },
    getNodeStyle(index) {
      return this.nodes[index].active ? this.activeNodeStyle : this.inactiveNodeStyle;
    },
    getLineClassLeft(nodeIndex) {
      if (nodeIndex > 0) {
        if (this.activationRule === 'both') {
          // return this.nodes[nodeIndex].active || this.nodes[nodeIndex - 1].active ? 'active' : 'trans';
          return this.nodes[nodeIndex].active && this.nodes[nodeIndex > 0 ? nodeIndex - 1 : 0].active  ? 'active' : 'trans';
        }
        if (this.activationRule === 'right') {
          return this.nodes[nodeIndex - 1].active ? 'active' : 'trans';
        }
        if (this.activationRule === 'left') {
          return this.nodes[nodeIndex].active ? 'active' : 'trans';
        }
      }
      return 'trans';
    },
    getLineClassRight(nodeIndex) {
      if (nodeIndex < this.nodes.length - 1) {
        if (this.activationRule === 'both') {
          // return this.nodes[nodeIndex].active || this.nodes[nodeIndex + 1].active ? 'active' : 'trans';
          return this.nodes[nodeIndex].active   ? 'active' : 'trans';
        }
        if (this.activationRule === 'left') {
          return this.nodes[nodeIndex + 1].active ? 'active' : 'trans';
        }
        if (this.activationRule === 'right') {
          return this.nodes[nodeIndex].active ? 'active' : 'trans';
        }
      }
      return 'trans';
    },
    setTimelineItem(node){
      const singleIndexFirst = node != undefined && node.first;
      const singleIndexLast = node != undefined && node.last;
      let styleObj = {}
      if (singleIndexFirst) {
        styleObj = {...styleObj}
      } 
      if (singleIndexLast) {
        styleObj = {...styleObj,marginRight: '25px'}
      }
      return styleObj
    },
    getLineStyle(index, side, node) {
      const drawLeft = side === 'left';
      const drawRight = side === 'right';
      const indexFirst = index === 0;
      const indexLast = index === this.nodes.length - 1;
      const singleIndexFirst = node != undefined && node.first;
      const singleIndexLast = node != undefined && node.last;

      if ((drawLeft && indexFirst) || (drawRight && indexLast)) {
        return {backgroundColor: 'transparent'};
      }
      const isActive = side === 'left'
          ? (index > 0 && this.getLineClassLeft(index) === 'active')
          : (index < this.nodes.length - 1 && this.getLineClassRight(index) === 'active');

      let tempStyle = "";
      if (isActive) {
        tempStyle = this.activeLineStyle;
      } else {
        tempStyle = this.inactiveLineStyle;
      }

      if (drawLeft && singleIndexFirst) { 
        tempStyle = {...tempStyle, opacity: 0};
      }
      // if (drawRight && singleIndexLast) {
      //   tempStyle = {...tempStyle, marginRight: '25px'};
      // }
      return tempStyle;

    },
    toggleNode(index) {
      this.$emit('update:node', index);
    }
  }
};
</script>

<style scoped>
#timeline-container {
  padding: 10px 0;
  overflow-x: auto;
}
#timeline-container::-webkit-scrollbar {
    height: 10px;
}
#timeline-container::-webkit-scrollbar-thumb {
    background-color: #CDCDCD; 
    border-radius: 5px; 
}
#timeline-container::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

#timeline-container .scrollable {
  overflow-x: unset; /* 当传递 nodeLineWidth 时启用水平滚动 */
}

#timeline-container .timelineBg {
  display: flex;
  align-items: center;
  margin-left: 55px;
}

#timeline-container .timeline-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  height: 55px;
  justify-content: flex-start;
}

#timeline-container .timeline-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

#timeline-container .timeline-line .trans {
  background-color: transparent;
}

#timeline-container .timeline-node {
  border-radius: 50%;
  transition: background-color 0.3s;
  cursor: pointer;
  z-index: 1;
}

#timeline-container .timeline-node .active {
  background-color: #4A86E4;
}

#timeline-container .timeline-line {
  transition: background-color 0.3s;
  /* flex-grow: 1; */
  width: 95px;
}

#timeline-container .timeline-line .active {
  background-color: #4A86E4;
}

#timeline-container .timeline-text {
  white-space: nowrap;
  margin: 2px 0;
}
#timeline-container .timeline-text-height {
  height: 19px;
}

#timeline-container .timeline-text .top {
  margin-bottom: 10px;
}

#timeline-container .timeline-text .bottom {
  margin-top: 10px;
}
</style>
