var esFilteList = function (Vue) {
    var template = '<div class="es-filter-container">\
                <div v-if="parent.loading" class="loading-container" :style="loadingStyle">正在加载....</div>\
                <div class="es-filter-list" v-if="showPopper">\
                    <div v-for="l in list" :value="l[props.value]" @click="select(l)">{{l[props.label]}}</div>\
                </div>\
            </div>'
    
    Vue.component('es-filter-list', {
        componentName: 'es-filter-list',
        template: template,
        props:{
            props: [Object],
            list: [Object, Array],
            appendToBody: {
                type: Boolean,
                default: true
            }
        },
        data: function () {
            return {
                parent: this.$parent,
                showPopper: false,
                loadingStyle: {
                    width: '150px'
                }
            }
        },
        methods: {
          select: function (item) {
              this.dispatch('es-autocomplete', 'item-click', item);
          },
            dispatch: function(componentName, eventName, params) {
                var parent = this.$parent || this.$root;
                var name = parent.$options.componentName;

                while (parent && (!name || name !== componentName)) {
                    parent = parent.$parent;

                    if (parent) {
                        name = parent.$options.componentName;
                    }
                }
                if (parent) {
                    parent.$emit.apply(parent, [eventName].concat(params));
                }
            }
        },
        watch: {
            showPopper: function (val) {
                var inp = $(this.referenceElement)
                var height = inp.outerHeight()
                var width = inp.outerWidth()

                this.$nextTick(function () {
                    $(this.$el).css({
                        top: inp.offset().top - $(document).scrollTop() + height,
                        left: inp.offset().left
                    })
                })

                if(this.appendToBody){
                    document.body.appendChild(this.popperElement)
                }
            }
        },
        mounted: function () {
            this.popperElement = this.$el
            this.referenceElement = this.$parent.$refs.input.$refs.input

            console.log(this.popperElement)
            console.log(this.referenceElement)
        },
        created: function () {
            var self = this
            this.$on('visible', function (val, width) {
                self.showPopper = val
            })
        }
    })
    
}

module.exports = esFilteList