<template>
    <dk-modal :title="blNoModalTitle" :fade="show" :style-objs="{'width':'100%','max-width':'100%'}" zindex="50" @change="toggleModal()">
        <div id="blNoData" style="min-height:600px;max-height:1000px">
            <ul class="nav nav-tabs no-margin-bottom">
                <li class="active"><a data-toggle="tab" @click="showTable('openingDto')">开港</a></li>
                <li><a data-toggle="tab" @click="showTable('sailingDto')">船期</a></li>
                <li><a data-toggle="tab" @click="showTable('dockingDto')">靠泊</a></li>
            </ul>
            <div class="notice">注意:{{msg}}</div>
            <table class="table table-striped table-bordered table-advance table-hover table-scrollable">
                <thead>
                    <tr><th v-for="thead in tableVlas">{{thead.name}}</th></tr>
                </thead>
                <tbody>
                    <tr><td v-for="thead in tableVlas" >
                        <a v-if="thead.hideVals"  @click="tdClick(thead)">挂靠港</a>
                        <span v-else-if="thead.key=='obOpeningTime'" :class="{'timeColor':openingTimeFlag}">{{thead.val}}</span>
                        <span v-else-if="thead.key=='actualBerthingTime'" :class="{'timeColor':berthingTimeFlag}">{{thead.val}}</span>
                        <span v-else>{{thead.val}}</span></td>
                    </tr>
                </tbody>
            </table>
            <div class="clear"></div>
            <div class="goods-style">
                <div class="title"><span class="title-name">箱货</span><span class="notice">注意：点击详情可查看箱详情时间</span></div>
                <table class="table table-striped table-bordered table-advance table-hover table-scrollable">
                    <thead>
                        <tr><th v-for="thead in ajaxDataGoods">{{thead.name}}</th></tr>
                    </thead>
                    <tbody>
                        <tr v-for="good in goodsList" :class="{'ctnColor':good.ctnNo == ctnNo}">
                            <td v-for="(val,index) in good.children">{{val}}<a v-if="index==0" @click="showDetail(good)">详情</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <dk-modal :title="callPortsModal.title" :fade="callPortsModal.fade" :style-objs="callPortsModal.styleObjs" :zindex="callPortsModal.zindex" @change="hideModal(callPortsModal)">
                <table class="table table-striped table-bordered table-advance table-hover table-scrollable">
                    <thead>
                        <tr><th>港序</th><th>代码</th><th>中文名</th><th>英文名</th></tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in callPortsList">
                            <td>{{item.seq}}</td>
                            <td>{{item.code}}</td>
                            <td>{{item.nameCn}}</td>
                            <td>{{item.nameEn}}</td>
                        </tr>
                    </tbody>
                </table>
            </dk-modal>
            <dk-modal :title="detailModal.title" :fade="detailModal.fade" :style-objs="detailModal.styleObjs" :zindex="detailModal.zindex" @change="hideModal(detailModal)">
                <div style="max-height:500px">
                    <table class="table table-bordered table-advance table-scrollable listColor">
                        <tbody>
                            <tr v-for="detailFistTable in detailFistTableList">
                                <td v-for="(item,index) in detailFistTable" :colspan="detailFistTable.length==2&&index==1?5:1">{{item}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="clear"></div>
                    <table class="table table-striped table-bordered table-advance table-hover table-scrollable">
                        <thead>
                            <tr><th v-for="houseBill in houseBillsList">{{houseBill.name}}</th></tr>
                        </thead>
                        <tbody>
                            <tr v-for="houseBill in houseBillsData">
                                <td v-for="val in houseBill.children">{{val}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="clear"></div>
                </div>
            </dk-modal>
        </div>
    </dk-modal>
</template>

<script>
    import dkModal from "dk-modal";
    export default {
        name: 'dk-bl-search',
        props: {
            blNo: {
                type: String,
                default: '',
            },
            ctnNo: {
                type: String,
                default: '',
            },
            show: {
                type: Boolean,
                default: false
            }
        },
        components: {
            "dk-modal": dkModal
        },
        data: function () {
            return {
                detailFistTableList:[],
                statusToName:{
                    'Customs Submittion':'海关放行',
                    'Pre-Manifest Release':'预配申报成功',
                    'Pre-Manifest Submittion':'预配已发送',
                    'Failed in Pre-Manifest Release':'预配申报失败',
                    'Loading Directly':'直装',
                    'Pickup Directly':'直提',
                    'Cargo Onboard':'装船',
                    'Discharge from Ship':'卸船',
                    'Gate In':'进场',
                    'Gate Out':'出场',
                    'Withdraw Loaded':'退装',
                    'Withdraw Discharged':'退卸',
                    'Be Withdrawed Loading':'被退装',
                    'Be Withdrawed Discharge':'被退卸',
                    'Ship to Notice':'运抵',
                    'Pickup Empty':'提空',
                    'Loading':'装货',
                    'Gate In Heavy Container':'进重',
                    'Customs Release':'海放',
                    'Terminal Release':'码放',
                    'Customs Inspection':'海关查验',
                    'Cross Trucking and Gate Out':'互拖出场',
                    'Cross Trucking and Gate In':'互拖进场',
                    'State Inspection':'国检查验',
                    'Cross the district and Gate Out':'跨区出场',
                    'Cross the district and Gate In':'跨区进场',
                    'Pre-Stowage':'配载',
                    'Unpacking and Gate In':'拆箱进场',
                    'Unpacking and Gate Out':'拆箱出场',
                    'Cancel Customs Release':'取消放关',
                    'Failed in Customs Release':'放行失败',
                    'Weight up VGM':'VGM称重',
                    'VGM received by Terminal':'VGM报文',
                    'Receive Packing List':'收到装箱单',
                    'Deal with Terminal Release':'外理放行',
                    'Deal with Packing List':'装箱单处理',
                    'DG Customs Release':'危类海事放行',
                    'State Inspection Release':'国检放行',
                },
                callPortsModal:{
                    title:'挂靠港',
                    fade:false,
                    styleObjs:{
                        width:'500px'
                    },
                    zindex:100
                },
                blNoModalTitle: '',
                detailModal:{
                    title:'详情',
                    fade:false,
                    styleObjs:{
                        width:'900px',
                    },
                    zindex:100
                },
                callPortsList:[],
                goodsDetail:[],
                tableVlas:[],
                baseData:{},
                houseBillsData:[],
                msg:'',
                openingTimeFlag:false,
                berthingTimeFlag:false,
                loadFlag:0,
                beginLoadFlag:0,
                msgObj:{
                    openingDto: '红色表示已开港。',
                    sailingDto:'船期为船代理输入的抵达上海港锚地的时间和预计离开上海港锚地时间，仅做参考，靠离泊时间以码头的靠泊数据为准。',
                    dockingDto:'红色表示已靠泊。'
                },
                ajaxData:{
                    openingDto:[
                        {name:'码头',key:'wharf'},
                        {name:'中文船名',key:'vesselZh'},
                        {name:'英文船名',key:'vesselUs'},
                        {name:'出口航次',key:'voyageOb'},
                        {name:'开港时间',key:'obOpeningTime'},
                        {name:'截港时间',key:'obCutOffTime'},
                        {name:'代理',key:'agencyShortName'},
                        {name:'挂靠港',key:'callPorts'}
                    ],
                    sailingDto:[
                        {name:'预靠码头',key:'wharf'},
                        {name:'中文船名',key:'vesselZh'},
                        {name:'英文船名',key:'vesselUs'},
                        {name:'进口航次',key:'voyageIb'},
                        {name:'出口航次',key:'voyageOb'},
                        {name:'船代理',key:'agencyShortName'},
                        {name:'船期状态',key:'sailingStatus'},
                        {name:'计划抵港时间',key:'eta'},
                        {name:'计划离港时间',key:'etd'},
                        {name:'船舶类型',key:'shipType'}
                    ],
                    dockingDto:[
                        {name:'码头',key:'wharf'},
                        {name:'中文船名',key:'vesselZh'},
                        {name:'英文船名',key:'vesselUs'},
                        {name:'进口航次',key:'voyageIb'},
                        {name:'出口航次',key:'voyageOb'},
                        {name:'计划靠泊时间',key:'plannedDockingTime'},
                        {name:'实际靠泊时间',key:'actualBerthingTime'},
                        {name:'计划离泊时间',key:'planDepartureTime'},
                        {name:'实际离泊时间',key:'actualDepartureTime'}
                    ]
                },
                goodsList:[],
                ajaxDataGoods:[
                    {name:'状态',key:'currentTrackingStatus'},
                    {name:'码头',key:'terminal'},
                    {name:'箱号',key:'ctnNo'},
                    {name:'船名',key:'vessel'},
                    {name:'箱型',key:'sizeType&heightNum'},
                    {name:'温度',key:'temperature'},
                    {name:'危险',key:'dgClass'},
                    {name:'超限',key:'overLimit'},
                    {name:'进场时间',key:'gateInTime'},
                    {name:'出场时间',key:'gateOutTime'},
                    {name:'运抵发送',key:'flagShipToNotice'},
                    {name:'海关放关',key:'flagCustomsRelease'},
                    {name:'海事放行',key:'flagDgCustomsRelease'},
                    {name:'国检放行',key:'flagStateInspectionRelease'},
                    {name:'外理放行',key:'flagDealWithTerminalRelease'},
                    {name:'码头放行',key:'flagTerminalRelease'},
                    {name:'码头配载',key:'flagPreStowage'},
                ],
                detailList:[
                    {name:'码头',key:'terminal'},
                    {name:'箱号',key:'ctnNo'},
                    {name:'船名航次',key:'vessel&voyage',contactFlag:" "},
                    {name:'场箱位',key:'ctnPosition'},
                    {name:'箱型',key:'sizeType&heightNum',contactFlag:" "},
                    {name:'箱皮重',key:'tareWeight'},
                    {name:'状态',key:'statusCode&status',contactFlag:"/"},
                    {name:'装货港',key:'portOfLoading'},
                    {name:'箱货重',key:'weight'},
                    {name:'持箱人',key:'ctnHolder'},
                    {name:'卸货港',key:'portOfUnloading'},
                    {name:'铅封号',key:'sealNo'},
                    {name:'目的港',key:'portOfDestination'},
                    {name:'VGM（船公司）',key:'vgmWeight'},
                    {name:'进场时间',key:'gateInTime'},
                    {name:'VGM报文接收',key:'vgmReceivedByTerminalTime'},
                    {name:'出场时间',key:'gateOutTime'},
                    {name:'运抵报文时间',key:'shipToNoticeTime'},
                    {name:'海关放行',key:'flagCustomsRelease'},
                    {name:'冷藏温度',key:'temperature'},
                    {name:'码头放行',key:'flagTerminalRelease'},
                    {name:'危险品／联合国编号',key:'dgClass&unNo',contactFlag:"/"},
                    {name:'码头配载',key:'flagPreStowage'},
                    {name:'国检放行',key:'flagStateInspectionRelease'},
                    {name:'前／后超长（CM）',key:'beforeandafter'},
                    {name:'左／右超宽（CM）',key:'Leftandright'},
                    {name:'超高（CM）',key:'overHeight'},
                    {name:'海事放行异常',key:'flagFailedInCustomsRelease'},
                ],
                houseBillsList:[
                    {name:'进出口',key:'boundType'},
                    {name:'提单号',key:'hblNo'},
                    {name:'分件数',key:'totalNumberOfPackages'},
                    {name:'分重量',key:'grossWeightOfCargo'},
                    {name:'分体积',key:'totalVolumeOfCargo'},
                    {name:'报关单号',key:'customsDeclarationNo'},
                    {name:'码头放行',key:'flagPreStowage'}
                ]

            }
        },
        computed: {

        },
        mounted: function(){

        },
        methods: {
            formatDetailList:function(res){
                var that = this;
                var arr = [];
                that.detailList.forEach(function(item,index){
                    var  over = res.obj['overLength'].split("/");
                    if(item.key.indexOf("&")>0){
                        res.obj[item.key] =  res.obj[item.key.split("&")[0]] + item.contactFlag + res.obj[item.key.split("&")[1]];
                    }
                    if(item.key == "beforeandafter"){
                        res.obj.beforeandafter=over[0]+'/'+over[1];
                    }
                    if(item.key == "Leftandright"){
                        res.obj.Leftandright=over[2]+'/'+over[3];
                    }
                    item.val = res.obj[item.key];
                    if(item.val===true){
                        item.val = 'Y'
                    }
                    if(item.val===false){
                        item.val = ''
                    }
                    var newArr = [];
                    if(index==0){
                        newArr.push(item.name,item.val)
                        arr.push(newArr)
                    }else{
                        if(index%3==0){
                            var currentItem = item;
                            var prevItem =  that.detailList[index-1];
                            var prevPrevItem =  that.detailList[index-2];
                            newArr.push(prevPrevItem.name,prevPrevItem.val,prevItem.name,prevItem.val,currentItem.name,currentItem.val,)
                            arr.push(newArr)
                        }
                    }
                })
                that.detailFistTableList = arr;
            },
            tdClick:function(tdData){
                this.callPortsList = tdData.hideVals
                this.callPortsModal.fade = true
            },
            showDetail:function(goods){
                console.log("goods", goods)
                var sendViewObj = {
                    t6ContainerId: goods.t6ContainerId,
                    t4ContainerId: goods.t4ContainerId
                }
                var sendListObj = {
                    blNo: this.blNo,
                    ctnNo: goods.ctnNo
                }
                var that = this
                _.ui.mask();
                var arr = [];
                goods.houseBills.forEach(function(item,index){
                    var obj = item;
                    obj.children = [];
                    that.houseBillsList.forEach(function(v,index){
                        obj.children.push(item[v.key] || "")
                    })
                    arr.push(obj)
                })
                that.houseBillsData = arr;
                that.loadFlag += 1
                
                _.util.ajax_get('/logisData/trackingStatus/list', sendListObj, function (res) {
                    if (res.success) {
                        res.objs.forEach(function(v,i){
                            v.statusDesc = that.statusToName[v.statusDesc]
                        })
                        that.goodsDetail = res.objs
                        that.loadFlag += 1
                    }
                })


            },
            hideModal:function(modale){
                modale.fade = false
            },
            showTable:function(key){
                this.tableVlas = this.ajaxData[key]
                this.msg = this.msgObj[key]
                var date = new Date();
                this.openingTimeFlag = Date.parse(this.tableVlas[4].val)<Date.parse(date)?true:false;
                this.berthingTimeFlag = Date.parse(this.tableVlas[6].val)<Date.parse(date)?true:false;
            },
            getBaseDataByBlNo: function(){
                if(!this.blNo) return
                var sendObj = {
                    blNo: this.blNo
                }
                var that = this
                _.ui.mask();
                _.util.ajax_get('/logisData/carrierInfo', sendObj, function (res) {
                    if (res.success) {
                        res.obj = res.obj || {};
                        res.obj.openingDto = res.obj.openingDto || {};
                        res.obj.dockingDto = res.obj.dockingDto || {};
                        res.obj.sailingDto = res.obj.sailingDto || {};
                        that.baseData = res.obj;
                        that.$nextTick(function(){
                            for(var item in that.ajaxData){
                                that.ajaxData[item].forEach(function(v,i){
                                    if(v.key=='callPorts'){
                                        v.hideVals = res.obj[item][v.key] || ""
                                    }else{
                                        v.val = res.obj[item][v.key] || ""
                                    }

                                })
                            }
                            that.showTable("openingDto")
                            that.beginLoadFlag +=1
                        })
                    }

                })

            },
            getGoodsByBlNo: function(){
                if(!this.blNo) return
                var sendObj = {
                    blNo: this.blNo
                }
                var that = this
                _.ui.mask();
                _.util.ajax_get('/logisData/container/list', sendObj, function (res) {
                    if (res.success) {
                        var arr = [];
                        (res.objs || []).forEach(function(item,index){
                            var obj = item;
                            item.currentTrackingStatus = that.statusToName[item.currentTrackingStatus]
                            obj.children = [];
                            that.ajaxDataGoods.forEach(function(v,index){
                                if(item[v.key]===true){
                                    item[v.key]='Y'
                                }
                                if(v.key == "sizeType&heightNum"){
                                    item[v.key]= (item.sizeType || '') +" "+(item.heightNum || '')
                                }
                                obj.children.push(item[v.key] || "")
                            })
                            arr.push(obj)
                        })
                        that.goodsList = arr;
                        console.log("that.goodsList", that.goodsList)
                        that.beginLoadFlag +=1
                    }

                })

            },
            toggleModal: function() {
                this.fade = !this.fade;
                this.$emit("change", this.fade);
            }
        },
        watch: {
            loadFlag:function(){
                if(this.loadFlag==2){
                    this.detailModal.fade = true;
                    _.ui.unmask();
                    this.loadFlag = 0;
                }
            },
            blNo:function(){
                this.blNoModalTitle = "港区数据 - " + this.blNo;
                this.getBaseDataByBlNo()
                this.getGoodsByBlNo()
            },
            beginLoadFlag:function(){
                if(this.beginLoadFlag==2){
                    _.ui.unmask();
                    this.beginLoadFlag = 0;
                }
            }

        }
    }
</script>
<style>
    #blNoData .listColor{
        background: #ebeeee;
    }
    #blNoData .listColor td:nth-child(2n+1){
        background: #b4d3e8;
    }
    #blNoData .clear{
        height: 30px;
    }
    #blNoData .timeColor{
        color: red;
    }
    #blNoData .notice{
        color: #999;
        font-size: 12px;
    }
    #blNoData .title{
        height: 40px;
        line-height: 40px;
    }
    #blNoData .title-name{
        margin: 0 5px;
        font-size: 16px;
    }
    #blNoData .goods-style{
        min-width: 1250px;
    }
    #blNoData .ctnColor{
        font-weight: bold;
    }
</style>