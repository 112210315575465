var esAutocomplete = function (Vue) {
    function broadcast(componentName, eventName, params) {
        this.$children.forEach(function (child) {
            var name = child.$options.componentName;

            if (name === componentName) {
                child.$emit.apply(child, [eventName].concat(params));
            } else {
                broadcast.apply(child, [componentName, eventName].concat([params]));
            }
        });
    }

    var template = '<div>\
            <es-input\
            ref="input"\
            v-model="value"\
            :disabled="disabled"\
            @focus="handleFocus"\
            @blur="handleBlur"\
            @enter="handleKeyEnter"\
            @keyup="handleKeyUp"\
            ></es-input>\
            <es-filter-list :props="props" :list="list"></es-filter-list>\
        </div>'
    Vue.component("es-autocomplete", {
        componentName: "es-autocomplete",
        template: template,
        props: {
            props: {
                type: Object,
                default: {
                    label: 'text',
                    value: 'id'
                }
            },
            fetchFilter: Function,
            value: [String],
            disabled: [Boolean]
        },
        data: function () {
            return {
                isFocus: false,
                showPopper: false,
                list: [],
                loading: false
            }
        },
        methods: {
            getData: function (queryString) {
                var self = this
                this.loading = true;
                this.fetchFilter(queryString, function (list) {
                    self.loading = false;
                    if (Array.isArray(list)) {
                        self.list = list;
                    } else {
                        console.error('autocomplete list must be an array');
                    }
                });
            },
            handleKeyEnter: function() {

            },
            handleKeyUp: function (e) {
                console.log(e.target.value)
                var value = e.target.value
                this.getData(value)
            },
            handleFocus: function (e) {
                var value = e.target.value
                this.isFocus = true
                this.getData(value)
            },
            handleBlur: function (e) {
                var self = this
                setTimeout(function () {
                    self.isFocus = false;
                }, 100);
            },
            broadcast: function(componentName, eventName, params) {
                broadcast.call(this, componentName, eventName, params);
            },
            select: function(item) {
                var self = this
                this.$emit('input', item[this.props.value]);
                this.$emit('select', item);
                this.$nextTick(function () {
                    self.suggestions = [];
                })
            }
        },
        mounted: function () {
            var self = this
            this.$on('item-click', function (item) {
                self.select(item);
            });
        },
        computed: {
            showPopper: function () {
                return !this.loading && this.isFocus
            }
        },
        watch: {
            showPopper: function(val) {
                this.broadcast('es-filter-list', 'visible', [val, this.$refs.input.$refs.input.offsetWidth]);
            }
        }
    })

}
module.exports = esAutocomplete