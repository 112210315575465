var esOption = function (Vue) {
    var template = '<option :value="id" :key="key">{{text}}</option>'
    Vue.component('es-option', {
        template: template,
        props: {
            id: [String, Number],
            key: [String, Number],
            text: [String, Number]
        },
        data: function () {

        },
        methods:{

        }
    })
}
module.exports = esOption