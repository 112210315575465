var esC = function (Vue) {
    var template = '<div class="modal fade" id="box_list_cost_inputting" role="dialog" aria-labelledby="business-appoint-modal1" aria-hidden="true">\
                        <div class="modal-dialog modal-full">\
                            <div class="modal-content">\
                                <div class="modal-header">\
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" aria-hidden="true"><i class="iconfont icon-close"  @click="doClose"></i></button>\
                                    <h4 class="modal-title" id="business-appoint-modal1-label">{{title}}</h4>\
                                </div>\
                            <div class="modal-body">\
                                <div class="note note-info" style="margin-bottom:0;">\
                                <span v-for="(value, key,index) in showNotify">\
                                  <span >{{value}}{{costInputInfo[key]}}</span>\
                                  <br v-if="index==5||index==8||index==9" />\
                                </span>\
                                </div>\
                            <div class="input-cost-filter">\
                                <div class="cost-total full-left">\
                                    <label style="font-weight:600">{{typeInfo.feeName}}: </label><span class="font-red-mint bold">{{totalPrice}}</span><span class="unit">(元)</span>\
                                </div>\
                                <div class="show-look-cost-ask" @click="showLookCostAsk">查看司机申报费用</div>\
                                <div class="cost-filter-form full-right">\
                                    <input placeholder="添加费用项" esapp-id="addChargeItems" :disabled="costInputInfo[typeInfo.dis]" />\
                                </div>\
                            </div>\
                            <div class="cost-list">\
                                <div class="item" v-for="item in costInputInfo.chargeItems">\
                                    <label v-text="item.name"></label>\
                                    <input type="text" v-model="item.price" v-if="item.thisInputDisable" disabled/>\
                                    <input type="text" v-model="item.price" v-else-if="item.thisInputDisable" disabled/>\
                                    <input type="text" v-model="item.price" v-else-if="item.thisInputDisable" disabled/>\
                                    <input type="text" v-model="item.price" v-else :disabled="costInputInfo[typeInfo.dis]"/>\
                                    <i class="fa fa-minus font-red-mint bold" v-if="item.isAdd || !item[typeInfo.common]" @click="removeChargeItem(item.code)"></i>\
                                    <i style="position:absolute" data-title="" title="" @click="editRemark(item.remark,item.code,$event)" :class="(item.remark ? \'font-red-mint\' : \'\') + \' fa fa-pencil-square-o black\'" v-if="item.price"></i>\
                                </div>\
                                <div class="input-cost-remark">\
                                    <label class="control-label" style="float: left; height: 28px; line-height: 22px;width:80px">备注</label>\
                                    <textarea style="margin-left:25px;width:539px" v-model="costInputInfo[typeInfo.remark]" rows="1"></textarea>\
                                </div>\
                            </div>\
                            <div style="margin-top:10px" class="" v-if="costInputInfo.costs">\
                              <div class="" >\
                                <span style="font-weight:600" class="">应付司机：</span>\
                                <span v-for="(one,index) in costInputInfo.costs" v-if="costInputInfo.costs.length!=0">\
                                  <span>{{one.name}}</span>\
                                  <span >{{one.price}}</span>\
                                  <span v-if="one.remark">({{one.remark}})</span>\
                                  <span v-if="index!=costInputInfo.costs.length-1">，</span>\
                                </span>\
                              </div>\
                            </div>\
                            <div class="" v-if="costInputInfo.capitals">\
                              <div class="" >\
                                <span style="font-weight:600" class="">公司成本：</span>\
                                <span v-for="(one,index) in costInputInfo.capitals" v-if="costInputInfo.capitals.length!=0">\
                                  <span>{{one.name}}</span>\
                                  <span>{{one.price}}</span>\
                                  <span v-if="one.remark">({{one.remark}})</span>\
                                  <span v-if="index!=costInputInfo.capitals.length-1">，</span>\
                                </span>\
                              </div>\
                            </div>\
                        </div>\
                        <div class="modal-footer">\
                            <div class="popup-error-msg"></div>\
                                <button type="button" class="btn btn-default " data-dismiss="modal" @click="doClose">取消</button>\
                                <button type="button" class="btn btn-primary " @click="submitCost">保存</button>\
                                <button type="button" class="btn btn-primary " v-if="!costInputInfo.hasRevenueInvoiceMatched" @click="billOver">录账完成</button>\
                            </div>\
                        </div>\
                    </div>\
                  <charge-remark :remark="editItemRemark" ref="editChargeRemark" @confirm="changeRemark"></charge-remark>\
                </div>'

    Vue.component("charge-entry",{
        template: template,
        data: function () {
            return {
                editItemRemark: "",
                remarkChargeCode: "",
                showChargeRemark: false,
                chargeItems: {},
                selectChargeItems: {},
                typeInfo: {
                    type: 'revenue',
                    getValCode: "charges",
                    feeName: '应收总额',
                    common: 'isRevenueCommonlyUsed',
                    dis: 'hasRevenueInvoiceMatched',
                    remark: "chargeRemark"
                }
            }
        },
        props:{
            costInputInfo:{
                type: Object,
                default: {}
            },
            allChargeItems: {
                type: Object,
                default: {}
            },
            title: {
                type: String,
                default: '费用录入'
            },
            thisId:"",
            showNotify:{
                type: Object,
                default:{
                    customerName: "客户：",
                    ctnNo: "箱号：",
                    ctnSizeType: "箱型：",
                    weight:"货重：",
                    truckPlateNumber:"车牌号：",
                    driverName:"司机：",

                    blNo: "提单号：",
                    portDistrict: "港区：",
                    depot:"堆场：",

                    doorAddress: "门点地址：",

                    dispatchRequirement:"做箱要求：",
                    remark:"备注：",
                },
            },
            showType: {
                type: String,
                default: 'revenue'
            }
        },
        computed: {
            totalPrice: function() {
                var totalPrice = 0;
                $.each(this.costInputInfo.chargeItems, function(_, v) {
                    totalPrice += parseFloat(v.price) || 0;
                    if(v.name=="运费"||v.name=="进阳山费"||v.name=="特种箱费"){
                      v.thisInputDisable = true;
                    }
                });
                return totalPrice.toFixed(2);
            },
            // totalPrice:function(){
            //   var that = this;
            //   for(var i in ){
            //     chargeItems
            //   }
            // }
        },
        methods:{
            showLookCostAsk:function(){
                this.$emit('show-look-cost');
            },
            billOver:function(){
              var that = this;
              
              
              var ids = [];
              if(that.thisId){
                ids.push(that.thisId)
              }else{
                _.ui.notify({ message: "id异常",type:"warning" });
                return ;
              }
              var charges = [];
                $.each(this.costInputInfo.chargeItems, function(_, v) {
                    if (v.price) {
                        charges.push(v);
                    }
                });
                var data = {
                    containerId: this.costInputInfo.containerId,
                    charges: charges
                }

                data[this.typeInfo.remark] = this.costInputInfo[this.typeInfo.remark] //根据类型去获取对应的值 //应收应付成本
                _.util.ajax_submit("/trucker_charge/addTruckChargesHarbour", data, function(res) {

                })
              _.ui.confirm({
                    innerHTML: "确定完成录账?",
                    success: function() { 
                   	var state = 0
                      _.ui.mask()
                      $('.popup').remove();
                      _.util.ajax_submit('/trucker_charge/batchInvoiceMatchChargeHarbour', {containerIds: ids}, function() {
	                      	_.ui.notify({
		                        message: "录账成功！"
		                    })
                            $("#box_list_cost_inputting").modal('hide');
                            state++
                      })
                      
                  }
              });
            },
            editRemark: function(remark, code, e) {
                this.$data.editItemRemark = remark;
                this.$nextTick(function() {
                    this.$data.remarkChargeCode = code;
                    this.$refs.editChargeRemark.show(e);
                });
            },
            changeRemark: function(value) {
                var self = this;
                $.each(this.costInputInfo.chargeItems, function(_, v) {
                    if (self.$data.remarkChargeCode == v.code) {
                        v.remark = value;
                        self.$set(self.costInputInfo.chargeItems, _, v);
                        return true;
                    }
                });
            },
            submitCost: function () {
                $("#box_list_cost_inputting").modal('hide');
                _.ui.mask();
                var costInputInfo = _.cloneDeep(this.costInputInfo);
                var charges = [];
                var hasAddChargeCode = [];
                $.each(costInputInfo.chargeItems, function(_, v) {
                    if (v.price) {
                        charges.push(v);
                        hasAddChargeCode.push(v.code);
                    }
                });
                $.each(costInputInfo.charges, function(_, v) {
                        if (hasAddChargeCode.indexOf(v.chargeItemId) < 0) {
                            v.code = v.chargeItemId;
                            v.price = 0;
                            charges.push(v);
                        }
                    });
                var data = {
                    containerId: this.costInputInfo.containerId,
                    charges: charges
                }

                data[this.typeInfo.remark] = this.costInputInfo[this.typeInfo.remark] //根据类型去获取对应的值 //应收应付成本
                _.util.ajax_submit("/trucker_charge/addTruckChargesHarbour", data, function(res) {
                    _.ui.notify({
                        message: "编辑费用成功！"
                    })
                    _.ui.unmask();
                })
            },
            initCharges: function () {
                var self = this
                if(!this.costInputInfo) return
                //将有值的费用 加入到 chargeItems里面去
                var newChargeItem = []
                var commonChargeItemObj = {}
                this.chargeItems.forEach(function (v) {
                    commonChargeItemObj[v.code] = v
                })
                var a = this.costInputInfo[this.typeInfo.getValCode] //根据类型去获取对应的值 //应收应付成本
                a.forEach(function (v) {
                    v.code = v.chargeItemId;
                    if(commonChargeItemObj[v.code]){
                        commonChargeItemObj[v.code] = _.clone(_.merge(commonChargeItemObj[v.code], v))
                    }else {
                        commonChargeItemObj[v.code] = v
                    }
                })
                $.each(commonChargeItemObj, function(_, v) {
                    newChargeItem.push(v);
                });
                self.$set(self.costInputInfo, "chargeItems", newChargeItem);
            },
            initSelectChargeItems: function(){
                //区分常用和不常用
                var self = this
                var costCommonlyList = [],
                    selectChargeItems = [];
                $.each(this.allChargeItems, function(index, item) {
                    if (item[self.typeInfo.common]) { //根据类型去获取对应的值 //应收应付成本
                        costCommonlyList.push(_.util.merge(item, { chargeItemId: item.code }));
                    } else {
                        selectChargeItems.push(_.util.merge(item, { chargeItemId: item.code }));
                    }
                });

                this.chargeItems = costCommonlyList;
                this.selectChargeItems = selectChargeItems
            },
            show: function () {
                $('#box_list_cost_inputting').appendTo($('body'));
                $("#box_list_cost_inputting").modal({ keyboard: true });
                this.initTypeInfo();
                this.initSelectChargeItems();
                this.initCharges();
                this.initInput();
            },
            initTypeInfo: function () {
                if(this.showType == 'revenue'){
                    this.$data.typeInfo = {
                        type: 'revenue',
                        getValCode: "charges",
                        feeName: '应收总额',
                        common: 'isRevenueCommonlyUsed',
                        dis: 'hasRevenueInvoiceMatched',
                        remark: "chargeRemark"
                    }
                }else if(this.showType == 'cost'){
                    this.$data.typeInfo = {
                        type: 'cost',
                        getValCode: "costs",
                        feeName: '应付总额',
                        common: 'isCostCommonlyUsed',
                        dis: 'hasCostInvoiceMatched',
                        remark: "costRemark"
                    }
                }else if(this.showType == 'capital'){
                    this.$data.typeInfo = {
                        type: 'capital',
                        getValCode: "capitals",
                        feeName: '成本总额',
                        common: 'isCapitalCommonlyUsed',
                        dis: 'hasCapitalInvoiceMatched',
                        remark: "capitalRemark"
                    }
                }
            },
            removeChargeItem: function(code) {
                var data = [];
                $.each(_.cloneDeep(this.costInputInfo.chargeItems), function(k, v) {
                    if (v.code != code) {
                        data.push(_.cloneDeep(v));
                    }
                })
                this.$set(this.costInputInfo, "chargeItems", data);
            },
            initInput: function () {
                var self = this;
                var chargeItems = esWidget.esInputFilter({
                    id: 'addChargeItems',
                    data: self.selectChargeItems,
                    allow_enter: true,
                    value_field: function(item) {
                        return item.code
                    },
                    display_field: function(item) {
                        return item.name
                    },
                    filter: function(dataList, display, value) {
                        var ret = []
                        $.each(dataList, function(i, v) {
                            var pinyin = _.util.pinyin.get(v.name)
                            var shorts = _.util.pinyin.returnCaps(pinyin)
                            if (v.name.toLowerCase().indexOf(display.toLowerCase()) >= 0 ||
                                pinyin.toLowerCase().indexOf(display.toLowerCase()) >= 0 ||
                                shorts.toLowerCase().indexOf(display.toLowerCase()) >= 0) {
                                ret.push(v)
                            }
                        })
                        return ret
                    }
                });

                _.event.listen('addChargeItems', 'change', function(res) {
                    var charges = _.cloneDeep(self.costInputInfo.chargeItems),
                        data = _.cloneDeep(self.costInputInfo.chargeItems),
                        checkList = {};
                    $.each(data, function(_, v) {
                        checkList[v.code] = v;
                    });
                    if (checkList[res]) return;
                    $.each(_.cloneDeep(self.selectChargeItems), function(k, v) {
                        if (v.code == res) {
                            v.price = undefined;
                            v.isAdd = true;
                            charges.push(v);
                            self.$set(self.costInputInfo, "chargeItems", charges);
                            return true;
                        }
                    })
                })
            },
            doClose: function () {
                this.$refs.editChargeRemark.hide();
            }
        }
    })
}
module.exports = esC
