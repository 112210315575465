var esInput = function (Vue) {
    function broadcast(componentName, eventName, params) {
        this.$children.forEach(function (child) {
            var name = child.$options.componentName;

            if (name === componentName) {
                child.$emit.apply(child, [eventName].concat(params));
            } else {
                broadcast.apply(child, [componentName, eventName].concat([params]));
            }
        });
    }

    Vue.component("es-input",{
        componentName: "es-input",

        template: '<input \
        v-if="type !== \'textarea\'" \
        :type="type" \
        class="form-control" \
        :esapp-id="id" \
        :name="name"\
        ref="input"\
        v-bind:value="value" \
        @input="handleInput"\
        @blur="handleBlur" \
        @focus="handleFocus" \
        @keyup="handleKeyUp" \
        :readonly="readonly" \
        autocomplete="off" />\
        <textarea \
        v-else \
        class="form-control" \
        ref="textarea"\
        v-bind:value="value" \
        @input="handleInput"\
        @blur="handleBlur" \
        @focus="handleFocus"  \
        :readonly="readonly" >\
        </textarea>',

        data: function () {
            return {

            }
        },
        props:{
            value:{
                default: ""
            },
            readonly:{
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: 'text'
            },
            id: [String],
            name: [String]
        },
        methods:{
            handleInput: function (e) {
                const value = event.target.value;
                this.$emit('input', value);
                this.$emit('change', e);
                this.dispatch('es-form-item', 'es.form.change', [value]);
            },
            handleBlur: function (e) {
                var value = e.target.value
                this.$emit('blur',e)
                this.dispatch('es-form-item', 'es.form.blur', [value]);
            },
            handleFocus: function (e) {
                var value = e.target.value
                this.$emit('focus',e)
                this.dispatch('es-form-item', 'es.form.focus', [value]);
            },
            handleKeyUp: function (e) {
                var value = e.target.value
                this.$emit('keyup',e)
                this.dispatch('es-form-item', 'es.form.keyup', [value]);
            },
            dispatch: function (componentName, eventName, params) {
                var parent = this.$parent || this.$root;
                var name = parent.$options.componentName;

                while (parent && (!name || name !== componentName)) {
                    parent = parent.$parent;

                    if (parent) {
                        name = parent.$options.componentName;
                    }
                }
                if (parent) {
                    parent.$emit.apply(parent, [eventName].concat(params));
                }
            },
            broadcast: function (componentName, eventName, params) {
                broadcast.call(this, componentName, eventName, params);
            }
        }
    })
}
module.exports = esInput
