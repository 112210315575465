var esSelect = function (Vue) {
    var template = '<select class="form-control">\
            <slot></slot>\
        </select>'
    Vue.component('es-select',{
        template: template,
        props:{
            
        },
        data: function () {
            return {}
        },
        methods:{

        }
    })
}
module.exports = esSelect