var esRadio = function (Vue) {
    var template = '<span class="radio-item-span"><input type="radio" v-model="value" :value="label" :name="name" @change="handleChange" /><label><slot></slot></label></span>'
    Vue.component('es-radio', {
        template: template,
        props: {
            value: [String],
            label: [String],
            label: [String],
            name: [String]
        },
        data: function () {
            return {}
        },
        methods: {
            handleChange: function (e) {
                var value = e.target.value
                this.$emit('input', value);
                this.$emit('change', e);
            }
        }
    })
}
module.exports = esRadio