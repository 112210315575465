var esForm = function (Vue) {
    Vue.component("es-form",{
        componentName: "es-form",
        template: '<div class="bs-example form-group"><form class="es-form">\
                <slot></slot>\
            </form></div>',
        data: function () {
            return {
                fields: []
            }
        },
        props:{
            model:Object,
            rules: Object
        },
        created: function () {
            this.$on('es.form.addField', function (field) {
                if (field) {
                    this.fields.push(field);
                }
            });
            this.$on('es.form.removeField',function (field) {
                if (field.prop) {
                    this.fields.splice(this.fields.indexOf(field), 1);
                }
            })
        },
        methods:{
            validate: function (callback) {
                var self = this;
                var valid = true;
                var count = 0;
                // 如果需要验证的fields为空，调用验证时立刻返回callback
                if (this.fields.length === 0 && callback) {
                    callback(true);
                }
                this.fields.forEach(function (field, index) {
                    field.validate( '', function (errors) {
                        if (errors) {
                            valid = false;
                        }
                        if (typeof callback === 'function' && ++count === self.fields.length) {
                            callback(valid);
                        }
                    })
                })
            }
        }
    })
}
module.exports = esForm
