const test = {
    QQ: {
			authAppId: 202042094,
			sid: 1300000778
    }
  }
  const prod = {
    QQ: {
			authAppId: 202042094,
			sid: 1300000736
    }
  }
  function getAccountQQ() {
    if (isProd()) {
        return prod.QQ
    } else {
        return test.QQ
    }
  }
  function getBaseUrlQQ() {
    if (isProd()) {
      return 'https://qq.yazuishou.com/gw'
    } else {
      return '/gw'
    }
  }
  
  // 是否是生产环境
  function isProd() {
    // 根据域名判断
    if (document.domain.indexOf('new.carrierglobe.com') > -1) { // 生产环境
      return true
    }
    return false
  }
  
	const accountQQ = getAccountQQ()
	const baseUrlQQ = getBaseUrlQQ()
  
export {
	accountQQ,
	baseUrlQQ
}