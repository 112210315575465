var esFormItem = function (Vue) {
    function broadcast(componentName, eventName, params) {
        this.$children.forEach(function (child) {
            var name = child.$options.componentName;

            if (name === componentName) {
                child.$emit.apply(child, [eventName].concat(params));
            } else {
                broadcast.apply(child, [componentName, eventName].concat([params]));
            }
        });
    }

    Vue.component("es-form-item",{
        componentName: "es-form-item",
        template: '\
            <div class="input-group" :class="{\'is-required\': isRequired || required }" >\
                <label :style="{width:labelWidth + \'px\'}" class="control-label"><i v-if="isRequired" class="fa fa-asterisk"></i>{{label}}</label>\
                <div>\
                    <slot></slot>\
                    <div class="msg-tip error" :data-direction="position" v-if="showMessage">{{msg}}</div>\
                </div>\
                <span><slot name="append"></slot></span>\
            </div>',

        data: function () {
            return {
                msg: ""
            }
        },
        props:{
            label: String,
            prop: String,
            required: Boolean,
            labelWidth:Number,
            rules: [Object, Array],
            showMessage: {
                type: Boolean,
                default: false
            },
            position: {
                type: String,
                default: 'top'
            }
        },
        computed: {
            form: function () {
                var parent = this.$parent;
                while (parent.$options.componentName !== 'es-form') {
                    parent = parent.$parent;
                }
                return parent;
            },
            isRequired: function () {
                var rules = this.getRules();
                var isRequired = false;
                if (rules && rules.length) {
                    rules.forEach(function (rule) {
                        if (rule.required) {
                            isRequired = true;
                            return false;
                        }
                        return true;
                    })
                }
                return isRequired;
            }
        },
        methods:{
            dispatch: function (componentName, eventName, params) {
                var parent = this.$parent || this.$root;
                var name = parent.$options.componentName;

                while (parent && (!name || name !== componentName)) {
                    parent = parent.$parent;

                    if (parent) {
                        name = parent.$options.componentName;
                    }
                }
                if (parent) {
                    parent.$emit.apply(parent, [eventName].concat(params));
                }
            },
            broadcast: function (componentName, eventName, params) {
                broadcast.call(this, componentName, eventName, params);
            },
            validate: function (trigger, callback) {
                if(!callback){
                    callback = function () {}
                }
                var rules = this.getFilteredRule(trigger);
                if (!rules || rules.length === 0) {
                    callback();
                    return true;
                }

                var value = this.form.model[this.prop]
                var self = this
                rules.forEach(function (v) {
                    if(!value && v.required){
                        self.showMessage = true;
                        self.msg = v.msg;
                        return false
                    }else{
                        self.showMessage = false;
                        self.msg = "";
                    }
                })
                callback(this.msg)
            },
            getRules: function () {
                var formRules = this.form.rules;
                var selfRuels = this.rules;
                formRules = formRules ? formRules[this.prop] : [];
                return [].concat(selfRuels || formRules || []);
            },
            getFilteredRule: function (trigger) {
                var rules = this.getRules();
                return rules.filter(function (rule) {
                    return !rule.trigger || rule.trigger.indexOf(trigger) !== -1;
                });
            },
            onFieldBlur: function() {
                this.validate('blur');
            },
            onFieldChange: function() {
                if (this.validateDisabled) {
                    this.validateDisabled = false;
                    return;
                }
                this.validate('change');
            }
        },
        mounted:function() {
            if (this.prop) {
                //伪方法
                this.form[this.prop] = this
                //伪方法

                this.dispatch('es-form', 'es.form.addField', [this]);
                var initialValue = this.fieldValue;
                if (Array.isArray(initialValue)) {
                    initialValue = [].concat(initialValue);
                }
                Object.defineProperty(this, 'initialValue', {
                    value: initialValue
                });
                var rules = this.getRules();
                if (rules.length) {
                    this.$on('es.form.blur', this.onFieldBlur);
                    this.$on('es.form.change', this.onFieldChange);
                }
            }
        },
        beforeDestroy: function () {
            this.dispatch('es-form', 'es.form.removeField', [this]);
        }

    })
}
module.exports = esFormItem
