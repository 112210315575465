const axios = require('axios')
const configs = require('../config/const')
const  jwtDecode = require('jwt-decode').default
const tools = require('../js/tools')
const http  = axios.create({
  baseURL: '/gw',
  headers: {
    "Content-Type": "application/json; charset=UTF-8"
  }
})

http.defaults.transformRequest = [
  data => {
    if ((typeof FormData !== 'undefined') && (data instanceof FormData)) {
      return data
    }
    // 为每个请求自动注入 meta, 请求可以直接传 params 下面的对象，也可以传整个{ params: object, meta: object, page?: object }
    const meta = genMeta();
    const payload = _.has(data, "params")
      ? Object.assign({ meta }, data)
      : {
          meta,
          params: data
        };
    return JSON.stringify(payload);
  }
]

http.interceptors.request.use(
  config => {
    config.headers['Authorization'] = localStorage.getItem('login-token')
    return config;
  },
  err => {
    return err;
  }
);
http.interceptors.response.use(
  resp => {
    const { config } = resp;
    if (resp && !resp.data.success) {
      _.ui.notify({
        message: resp.data.msg || "未知错误",
        type: 'error'
      });
      return Promise.reject(resp);
    } else {
      return resp;
    }
  })

const harbourHttp = axios.create({
  baseURL: "/ws-truck",
  headers: {
    "X-Requested-By": "eshipping",
    "Content-Type": "application/json; charset=UTF-8"
  }
});

const httpQQ  = axios.create({
  baseURL: configs.baseUrlQQ,
  headers: {
    "X-Requested-By": "eshipping",
    "Content-Type": "application/json; charset=UTF-8"
  },
  // Authorization: localStorage.getItem('login-token')
})
httpQQ.interceptors.request.use(
  config => {
    if (config.data) {
      config.data['authAppId'] = configs.accountQQ.authAppId
      config.data['sid'] = configs.accountQQ.sid
    }
    return config;
  },
  err => {
    return err;
  }
);
httpQQ.interceptors.response.use(
  resp => {
    const { config } = resp;
    if (resp && !resp.data.success) {
      _.ui.notify({
        message: resp.data.msg || "未知错误",
        type: 'error'
      });
      return Promise.reject(resp);
    } else {
      return resp;
    }
})

harbourHttp.defaults.transformResponse = [
  res => {
    return JSON.parse(res);
  }
];
harbourHttp.interceptors.response.use(
  resp => {
    console.log('resprespresp',resp)
    if (resp.config.parseResp === true) {
      let data = _.get(resp, 'data.data.value')
      try {
        let result = JSON.parse(data)
        resp.data.data = result
      } catch (error) {
        resp.data.data = data
      }
      return resp
    }
    return resp;
  },
  err => {
    console.log('errerr',err)
    return err
  }
);

const httpFormData = axios.create({
  baseURL: '/gw'
})

httpFormData.defaults.transformRequest = [
  data => { return data }
]

httpFormData.interceptors.request.use(
  config => {
    config.headers['Authorization'] = localStorage.getItem('login-token')
})

const httpDownload  = axios.create({
  baseURL: '/gw',
  headers: {
    "Content-Type": "application/json; charset=UTF-8"
  }
})

httpDownload.defaults.transformRequest = [
  data => {
    if ((typeof FormData !== 'undefined') && (data instanceof FormData)) {
      return data
    }
    // 为每个请求自动注入 meta, 请求可以直接传 params 下面的对象，也可以传整个{ params: object, meta: object, page?: object }
    const meta = genMeta();
    const payload = _.has(data, "params")
      ? Object.assign({ meta }, data)
      : {
          meta,
          params: data
        };
    return JSON.stringify(payload);
  }
]

httpDownload.interceptors.request.use(
  config => {
    config.headers['Authorization'] = localStorage.getItem('login-token')
    config.responseType = 'blob';
    return config;
  },
  err => {
    return err;
  }
);
httpDownload.interceptors.response.use(
  resp => {
    const { config } = resp;
    if (!resp ) {
      _.ui.notify({
        message: resp.data.msg || "未知错误",
        type: 'error'
      });
      return Promise.reject(resp);
    } else {
      return resp;
    }
  })
function genMeta(data = {}) {
  return Object.assign({
    code: "default",
    client: "PC",
    tag: "default",
    time: Date.now(),
  }, data);
}

// 新用户中心请求封装
let iamUrl = tools.getIamUrl()
const iamHttp = axios.create({
    baseURL: iamUrl
});
// 异常拦截处理器
const errorHandler = async (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        _.ui.notify({
          message: '错误',
          type: 'error'
        })
      }
      if (error.response.status === 404) {
        _.ui.notify({
            message: '错误',
            type: 'error'
          })
      }
      if (error.response.status === 400) {
        _.ui.notify({
            message: '错误',
            type: 'error'
          })
      }
      if (error.response.status === 500) {
        _.ui.notify({
            message: '错误',
            type: 'error'
          })
      }
      if (error.response.status === 401) {
        // _.ui.alert({
        //     key: '请重新登录',
        //     type: 'error',
        //     after_callback: function() {
        //         window.location.href = "login.html"
        //     }
        // })
        window.location.href = "login.html"
      }
    }
    return Promise.reject(error)
  }
iamHttp.interceptors.request.use(
    config => {
      if (config.url.includes('/cdr/export/file')) {
          config.responseType = 'blob' //请求加上responseType
       }
      const token = localStorage.getItem('iam-token')
      var isRefreshing = false // 是否有请求正在刷新token
      if (token) {
        config.headers['Authorization'] = token
        // token续期
        const code = jwtDecode(token)
        const current = new Date().getTime()
        //  过期时间小于当前时间
        if (code.exp * 1000 < current) {
          // 过期时间减去十分钟小于于当前时间
        } else if (code.exp * 1000 - 10 * 60 * 1000 < current && config.url.indexOf('/renewal/token') === -1) {
          if (!isRefreshing) {
            isRefreshing = true
            // 调用续期方法
            let renewalurl = iamUrl + '/iam-auth-web/auth/user/renewal/token'
            axios({
                url: renewalurl,
                method: 'get',
                headers: {
                    'Authorization': token
                },
                params:{}
              }).then(res => {
                console.log(res)
                if (!res.data.success) {
                    _.ui.notify({
                        message: res.data.errMessage,
                        type: 'warning'
                    });
                } else {
                    isRefreshing = false
                    window.localStorage.setItem('iam-token', res.data.data)
                    window.localStorage.setItem('token', res.data.data)
                }
              })
          }
        }
      }
      return config;
    },
    errorHandler
  );
iamHttp.interceptors.response.use(
    resp => {
      if (resp.request.responseType === 'blob') return resp
      const { config } = resp;
      if (resp && !resp.data.success) {
        if (resp.config.url === '/call-center-web/agent/operate/hangup') {
            return resp
        } else {
            _.ui.notify({
              message: resp.data.msg || resp.data.errMessage || "未知错误",
              type: 'error'
            });
            return Promise.reject(resp);
        }
      } else {
        return resp;
      }
  }, errorHandler)
    

const httpAccounting = axios.create({
  baseURL: window.location.host.includes("fat")
    ? "https://api.fat.driverglobe.com/"
    : " https://api.carrierglobe.com/",
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "X-Requested-By": "eshipping",
    Authorization: localStorage.getItem("iam-token"),
  },
});

httpAccounting.interceptors.response.use(
  (resp) => {
    if (resp?.data.success === false) {
      _.ui.notify({
        message: resp.data.errMessage || resp.data.msg || "未知错误",
        type: "error",
      });
      return Promise.reject(resp.data);
    } else {
      return resp;
    }
  },
  (err) => {
    if (err.response) {
      _.ui.notify({
        message:
          err.response?.data?.msg ||
          err.response?.data?.message ||
          err.response?.data?.errMessage ||
          err.response.statusText ||
          "未知错误",
        type: "error",
      });
    }
    return Promise.reject(err);
  }
);
module.exports = {
  http,
  harbourHttp,
  genMeta,
  httpQQ,
  httpDownload,
  iamHttp,
  httpAccounting,
};
