<template>
    <div id="driver-incomplete-order">
        <div v-if="isShow" tabindex="0" @keyup.space="toggleModal">
            <div class="positionFixed" v-if="!fade" @mousedown="startDrag" :style="{ top: `${position.y}px`, left: `${position.x}px` }">查看司机最新业务</div>
            <dk-modal :fade='fade' :isShowTitle="false" :style-objs="styleObjs" v-else :zindex="-99899" @change="toggleModal">
                <div>
                    <span class="icon_remark">( <i class="el-icon-warning-outline" style="color:#999;font-size: 12px;"></i> 按【空格】键也可关闭此弹框 )</span>
                    <button type="button" class="close"  @click="toggleModal" aria-label="Close" aria-hidden="true"><i class="iconfont icon-close"></i></button>
                </div>
                <div v-loading="incompleteOrderLoading">
                    <div class="driver-info flex-column-start-center">
                        <div class="row flex-start-center">
                            <div class="col">
                                <div class="label">司机姓名:</div>
                                <span class="value">{{ driverInfo.driverName }}</span>
                            </div>
                            <div class="col">
                                <div class="label">手机号:</div>
                                <span class="value">{{ driverInfo.driverPhone }}</span>
                            </div>
                            <div class="col">
                                <dk-sign :is-self-support="driverInfo.driverVarietyDto && driverInfo.driverVarietyDto.isSelfSupport"
                                    :is-self-buy="driverInfo.driverVarietyDto && driverInfo.driverVarietyDto.isSelfBuy"
                                    :is-vip="driverInfo.driverVarietyDto && driverInfo.driverVarietyDto.isVip"
                                    :is-out="driverInfo.driverVarietyDto && driverInfo.driverVarietyDto.isOut"
                                    :vip-level="driverInfo.driverVarietyDto && driverInfo.driverVarietyDto.vipLevel"
                                    :is-first-taker-order="driverInfo.driverVarietyDto && driverInfo.driverVarietyDto.isFirstTakeOrder"></dk-sign>
                            </div>
                        </div>
                        <div class="row flex-start-center">
                            <div class="col">
                                <div class="label">车牌号:</div>
                                <span class="value">{{ driverInfo.truckPlateNumber }}</span>
                            </div>
                            <div class="col">
                                <div class="label">车龄:</div>
                                <span class="value">{{ driverInfo.truckAge }}</span>
                            </div>
                            <div class="col">
                                <div class="label">最大牵引箱货总重:</div>
                                <span class="value">{{driverInfo.truckTractionMass | filter_weight}}</span>
                            </div>
                            <div class="col">
                                <div class="label">车辆类型:</div>
                                <span class="value">{{ driverInfo.truckType }}</span>
                            </div>
                            <div class="col">
                                <div class="label">能源类型:</div>
                                <span class="value">{{ driverInfo.truckEnergyType }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="driver-position flex-start-center" v-loading="driverPositionLoading">
                        <i class="el-icon-refresh refresh" @click="refresh(true)" />
                        <div class="col flex-start-start">
                            <div class="label">当前车辆位置:</div>
                            <el-tooltip effect="dark" :content="carAdr" popper-class="incomplete-order-popper" placement="top-start">
                                <span class="value ellipsis-text">{{ carAdr }}</span>
                            </el-tooltip>
                        </div>
                        <div class="col flex-start-start">
                            <div class="label">当前司机手机位置:</div>
                            <el-tooltip effect="dark" :content="phoneAdr" popper-class="incomplete-order-popper" placement="top-start">
                                <span class="value ellipsis-text">{{ phoneAdr }}</span>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="incomplete-order" >
                        <span class="incomplete-order-title">未完成的订单 <i v-if="startDate" class="incomplete-order-title-startDate">(从{{ startDate | filter_time }}开始查询)</i></span>
                        <div class="order-box" :style="{maxHeight:getModalHeight()}">
                            <div class="fixedLine fixedLine-top" v-if="incompleteOrderList.length > 0" />
                            <dkTasksStatusCard v-for="(item,index) in incompleteOrderList" :key="`${item.uncompletedOrderInfoList.length === 0 ? index : index + '_' + item.uncompletedOrderInfoList[0].tocId}`" :order-item="{
                                orderInfoList:item.uncompletedOrderInfoList,
                                containerLogList:item.uncompletedContainerLogList,
                                containerChecked:item.uncompletedContainerChecked,
                            }" @hide-modal="isShow=false" />
                            <div class="fixedLine fixedLine-bottom" v-if="incompleteOrderList.length > 0" />
                        </div>
                    </div>
                </div>
            </dk-modal>
            <dkBlSearch :show="modalBlSearch.fade" :bl-no='modalBlSearch.blNo' :ctn-no='modalBlSearch.number' @change="modalBlSearch.blNo='';modalBlSearch.fade = false"></dkBlSearch>
        </div>
    </div>
</template>
<script>
    import dkTasksStatusCard from "dk-tasks-status-card"
    import dkSign from "dk-sign-list" 
    import dkBlSearch from "dk-bl-search"
    import dkModal from "dk-modal"
    export default {
        components:{ dkTasksStatusCard, dkSign, dkBlSearch, dkModal },
        data () {
            return {
                windowHeight:'', // 页面高度
                windowWidth:'', // 页面宽度
                isShow:false, // 关闭 整个组件
                fade:false, // 关闭弹框
                styleObjs:{ width:'1300px', maxWidth:'1300px' },
                driverId:undefined,
                truckPlateNumber:undefined,
                driverInfo:{}, // 司机信息
                carAdr:'', // 车辆定位
                phoneAdr:'', // 手机定位
                incompleteOrderList:[{ uncompletedOrderInfoList:[],  uncompletedContainerLogList:[] }] ,// 未完成订单
                startDate:undefined,
                modalBlSearch: {
                    fade:false,
                    blNo:'',
                    number:''
                },  
                isHideModalBtn:false,
                driverPositionLoading:false, // 司机定位 loading
                incompleteOrderLoading:false, // 组件 loading
                isDragging: false,
                position: { x: 50, y: 50 },
                oldPosition: { x: 0, y: 0 },
                dragStart: { x: 0, y: 0 },
                fadeModal:false,//阻止弹框
            }
        },
        filters:{
            filter_weight(data){
                if (!data) return ''
                const regex = /[\d.]+/g;
                const matches = data.match(regex);
                if (!matches) {
                    return "";
                }
                const result = matches.map(num => {
                    const parsedNum = parseFloat(num);
                    if (parsedNum > 1000) {
                    return (parsedNum / 1000).toFixed(3); // 保持三位小数，防止精度丢失
                    }
                    return parsedNum;
                });
                const weight = result.length > 0 ? `${Number(result[0])}吨` : ''
                return weight;
            },
            filter_time(date){
                return dayjs(date).format('MM-DD HH:mm')
            }
        },
        mounted(){
            this.$eventBus.$on('showGlobalDriverInfoModal',res=>{
                this.driverId = ( res.driverId && res.driverId.length > 0 ) ? res.driverId : res.driverVarietyDto.id
                this.truckPlateNumber = ( res.truckPlateNumber && res.truckPlateNumber.length > 0 ) ? res.truckPlateNumber : res.driverVarietyDto.truckPlateNumber
                this.isHideModalBtn = res.isHideModalBtn
                this.resetModal()
                /** 
                 * !res.isFade && (this.fade = true)
                 * 这行代码主要用于车辆跟踪统计表弹框,在切换上一个 或者 下一个 的时候 不一直弹框
                */
               console.log(res.isFade,111);
               this.getModalHeight()
               if (!res.isFade) {
                    this.fadeModal = true
                    this.getShowModal()
               }
               if(res.position){
                    this.position = { x: res.position.x, y: res.position.y }
               } else {
                this.position = { x: 50, y: 50 }
               }
                this.isShow = true
            })
            this.$eventBus.$on('hideGlobalDriverInfoModal',()=>{
                this.isShow = false
                this.fade = false
                this.fadeModal = false
                this.resetModal()
            })
            this.$eventBus.$on('byDriverIncompleteOrder',res=>{
                this.modalBlSearch = res
            })
        },
        watch:{
            fade(nVal,oVal){
                if (nVal && !this.fadeModal) {
                    this.getShowModal()
                }
            },
        },
        methods:{
            // ---------------- 悬浮按钮拖动 方法  start ------------
            startDrag(event) {
                this.isDragging = true;
                this.dragStart = {
                    x: event.clientX - this.position.x,
                    y: event.clientY - this.position.y,
                }; 
                this.oldPosition = _.cloneDeep(this.position)
                document.addEventListener('mousemove', this.onDrag);
                document.addEventListener('mouseup', this.endDrag);
            },
            onDrag(event) {
                if (this.isDragging) {
                    const positionX = event.clientX - this.dragStart.x
                    const positionY = event.clientY - this.dragStart.y
                    if (this.windowWidth && this.windowHeight) {
                        this.position = {
                        x: positionX < 0 ? 0 : positionX > this.windowWidth-160 ? this.windowWidth-160 : positionX,
                        y: positionY < 0 ? 0 : positionY > this.windowHeight-40 ? this.windowHeight-40 : positionY
                    };
                    }
                }
            },
            endDrag() {
                this.isDragging = false;
                document.removeEventListener('mousemove', this.onDrag);
                document.removeEventListener('mouseup', this.endDrag);
                /**
                 * 拖动功能 和 点击事件 冲突,通过对比新旧默认位置坐标判断是拖动还是点击
                 */
                if (this.oldPosition.x === this.position.x && this.oldPosition.y === this.position.y) {
                    this.showModal()
                }
            },
            // ---------------- 悬浮按钮拖动 方法  end ------------
            // 获取弹框高度
            getModalHeight(){
                this.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                return `${this.windowHeight-320}px`
            },
            // 打开弹框
            showModal(){
                this.fade = true
            },
            // 重置弹框
            resetModal(){
                this.driverInfo = {}
                this.carAdr = ''
                this.phoneAdr = ''
                this.incompleteOrderList = [{  uncompletedOrderInfoList:[],uncompletedContainerLogList:[] }]
            },
            // 关闭 弹框/组件
            toggleModal(){
                /**
                 * 关闭弹框逻辑现在分为三种情况
                 * 1.关闭弹框,悬浮按钮正常展示
                 * 2.跳转页面时直接关闭整个组件 使用 $eventBus.$emit('hideGlobalDriverInfoModal'),例如 在本组件中点击订单号跳转页面
                 * 3.跳转页面时 不 关闭整个组件,当点击弹框[关闭]按钮 或者 右上角的[x] 或者 按[空格]键时,关闭整个组件, 需要 $eventBus.$emit('showGlobalDriverInfoModal',{isHideModalBtn: true}),例如 司机热线页面 跳转
                */
                if (this.isHideModalBtn) {
                    this.isShow = false
                    this.fade = false
                } else {
                    this.fade = false
                }
            },
            // 重新获取司机定位
            refresh(isRefresh){

                if (isRefresh && this.truckPlateNumber) {
                    this.driverPositionLoading = true
                } else if (!this.truckPlateNumber) {
                    this.$message({
                        message:'此条数据没有司机车牌号',
                        type:'warning',
                        showClose:true,
                        customClass:'incomplete-message'
                    })
                    return
                }
                
                const params1 = { vclN: this.truckPlateNumber }
                const subFun1 =  _.util.http.get("/ws-truck/zhiyun/api/findCarNo",{ params: params1 } )

                const params2 = { truckPlateNumber: this.truckPlateNumber }
                const subFun2 = _.util.http.get("/ws-truck/driver/last/location", { params: params2 })
                
                Promise.all([subFun1, subFun2]).then((res)=>{
                    if (res[0].success) {
                        // 车位置定位
                        this.carAdr = res[0]?.carInfo?.adr
                    }
                    if (res[1].success && res[1].data) {
                        // 司机手机定位
                        this.phoneAdr = res[1].data.value ? JSON.parse(res[1].data.value)?.carInfo?.adr : ''
                    }
                }).finally(()=>{
                    if (!isRefresh) return
                    this.driverPositionLoading = false
                    this.$message({
                        message:'刷新位置成功',
                        type:'success',
                        showClose:true,
                        customClass:'incomplete-message'
                    })
                })
            },
            // 获取司机信息
            getDriverInfo(){
                _.util.ajax_get("/task/uncompleted/getDriverInfo", { driverId:this.driverId },  res=>{
                    if (res.success) {
                        this.driverInfo = res.data.value ? JSON.parse(res.data.value) : {}
                    }
                })
            },
            // 查询司机当前未完成订单组列表详情
            getUncompletedOrderList(){
                this.incompleteOrderLoading = true
                _.util.ajax_get("/task/uncompleted/getUncompletedOrderList", { driverId:this.driverId }, res=>{
                    if (res.success && res.data && res.data.value) {
                        const jsonData = JSON.parse(res.data.value)
                        const incompleteOrderList = jsonData.uncompletedOrderModuleList
                        this.startDate = jsonData.startDate
                        this.$set(this,'incompleteOrderList',incompleteOrderList)
                        const incompleteOrderListLen = incompleteOrderList.map(i=>i.uncompletedOrderInfoList).reduce((pre,curr)=>pre.concat(curr),[]).length
                        console.log('incompleteOrderListLen====>',incompleteOrderListLen);
                        this.fade = true
                        if (incompleteOrderListLen > 30) {
                            _.ui.alert({
                                key: '该司机未完成订单数超过30条，请及时联系跟单处理',
                                type: 'error'
                            })
                        }
                        this.fadeModal = false
                    } else {
                        const incompleteOrderList = [{
                                uncompletedOrderInfoList:[],
                                uncompletedContainerLogList:[]
                               }]
                        this.startDate = undefined
                        this.fadeModal = false
                        this.$set(this,'incompleteOrderList',incompleteOrderList)
                    }
                    this.incompleteOrderLoading = false
                },()=>{
                    this.incompleteOrderLoading = false
                    this.fadeModal = false
                }) 
            },
            // 当弹框打开时调用的方法
            getShowModal(){
                if (!this.driverId) {
                        this.$message({
                        message:'此条数据没有司机id',
                        type:'warning',
                        showClose:true,
                        customClass:'incomplete-message'
                    })
                    return
                }
                this.getDriverInfo()
                this.refresh()
                this.getUncompletedOrderList()
            }
        }
    }
</script>
<style>
   #driver-incomplete-order  .order-box::-webkit-scrollbar {
        width: 10px;
    }
    #driver-incomplete-order .order-box::-webkit-scrollbar-thumb {
        background-color: #CDCDCD; 
        border-radius: 5px; 
    }
    #driver-incomplete-order .order-box::-webkit-scrollbar-thumb:hover {
        background-color: #999;
    }

    #driver-incomplete-order .positionFixed{
        position: fixed;
        cursor: pointer;
        z-index: 100000 !important;
        background-color: #fff;
        width: 180px;
        height: 40px;
        line-height: 38px;
        text-align: center;
        border-radius: 25px;
        font-size: 16px;
        color: #477DCD;
        border:2px solid #477DCD;
        box-shadow: 0px 1px 4px 0px #477DCD96;
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+ */
        user-select: none; /* Standard syntax */
    }
    #driver-incomplete-order .modal-body{
        padding: 0;
    }
    #driver-incomplete-order .close{
        position: absolute;
        right: 10px;
        top: 10px;
    }
    #driver-incomplete-order .icon_remark{
        position: absolute;
        font-size: 10px;
        color: #999;
        right: 40px;
        top: 16px;
    }
    
    #driver-incomplete-order .flex-column-start-center{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    #driver-incomplete-order .flex-start-start{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    #driver-incomplete-order .flex-start-center{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    #driver-incomplete-order .driver-info {
        padding: 15px;
        border-bottom: 1px solid #E5E5E5;
    }
    #driver-incomplete-order .driver-info .row{
        margin-bottom: 5px;
    }
    #driver-incomplete-order .col{
        min-width: 150px;
        margin-right: 5px;
    }
    #driver-incomplete-order .col .label{
        font-size: 14px;
        color: #111111;
        min-width:60px;
    }
    #driver-incomplete-order .col .value{
        color: #111111;
        font-weight: bolder;
        min-width:80px;
        max-width: 500px;
    }
    #driver-incomplete-order .driver-position{
        padding: 15px;
    }
    #driver-incomplete-order .refresh{
        color: #4A86E4;
        cursor: pointer;
        font-size: 16px;
    }
    #driver-incomplete-order .incomplete-order{
        padding: 0 15px 15px;
    }
    #driver-incomplete-order .incomplete-order-title{
        margin-bottom: 10px;
        overflow: hidden;
        font-size: 16px;
        font-weight: bold;
        color: #477DCD;
        display: block;
    }
     #driver-incomplete-order .incomplete-order-title-startDate{
        font-size: 12px;
        color: #111;
        font-style: normal;
    }
    #driver-incomplete-order .driver-position .ellipsis-text{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }
    #driver-incomplete-order .order-box{
        overflow-y: auto;
    }
    #driver-incomplete-order .order-box .fixedLine {
        position: sticky;
        z-index: 100002 !important;
        box-shadow: 0 0 5px 2px #e5e5e5;
        /* width: calc(100% - 45px); */
        width:100%;

        left: 18px;
    }
    #driver-incomplete-order .order-box .fixedLine-top {
        top: 3px;
    }
    #driver-incomplete-order .order-box .fixedLine-bottom {
        bottom: 0;
    }
    .incomplete-message{
        z-index: 100000 !important;
    }
   
</style>