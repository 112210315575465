var esChargeRemark = function (Vue) {
    Vue.component("charge-remark", {
        template: '<div class="popover-tip" v-if="isShow" ref="popoverBox" :style="styles">\
                                <div class="popover-title" v-text="this.titleName"></div>\
                                <div class="popover-content popover-info-wrapper">\
                                    <textarea v-model="remarkValue"></textarea>\
                                    <div class="popover-info">\
                                        <div v-if="remarkInfo.remarkCreateBy" class="created-by" >创建人：{{remarkInfo.remarkCreateBy}}</div>\
                                        <div v-if="remarkInfo.remarkCreationTime" class="created-date" >创建时间：{{remarkInfo.remarkCreationTime.substr(5,11)}}</div>\
                                        <div v-if="remarkInfo.remarkUpdateBy" class="update-by" >最后更新人：{{remarkInfo.remarkUpdateBy}}</div>\
                                        <div v-if="remarkInfo.remarkLastUpdateTime" class="update-time" >更新时间：{{remarkInfo.remarkLastUpdateTime.substr(5,11)}}</div>\
                                    </div>\
                                    <div class="popover-operation">\
                                        <a class="confirm btn-oper" @click="_confirmValue"><i class="fa fa-check"></i></a>\
                                        <a class="cancel btn-oper" @click="_closePopover"><i class="fa fa-times"></i></a>\
                                    </div>\
                                </div>\
                                <div class="arrow"></div>\
                            </div>',
        data: function() {
            return {
                isShow: false,
                styles: 'visibility: hidden;',
                remarkValue: ''
            }
        },
        props: {
            "remark": {
                type: "string",
                default: ''
            },
            "titleName": {
                type: "string",
                default: '编辑备注'
            },
            "remarkInfo":{
                type:'Object',
                default:{}
            }
        },
        methods: { ////
            show: function(e) {
                if (!e) return;
                this.$set(this.$data, "isShow", true);
                this.$set(this.$data, "remarkValue", this.remark);
                this.$nextTick(function() {
                    var clientWidth = this.$refs.popoverBox.clientWidth,
                        clientHeight = this.$refs.popoverBox.clientHeight,
                        clientX = $(e.target).offset().left,
                        clientY = $(e.target).offset().top,
                        targetHeight = e.target.clientHeight,
                        targetWidth = e.target.clientWidth,
                        styles = "";
                    styles = "left:" + ((clientX - clientWidth / 2) + targetWidth / 2) + 'px;top:' + (clientY - clientHeight - 2) + "px;";
                    this.$set(this.$data, 'styles', styles);
                    document.querySelector('body').appendChild(this.$refs.popoverBox);
                })

            },
            hide: function () {
                this.$set(this.$data, "isShow", false);
            },
            _initView: function() {
                this.$set(this.$data, 'styles', 'visibility: hidden;');
                this.$set(this.$data, 'remarkValue', '');
            },
            _closePopover: function() {
                this.$set(this.$data, 'isShow', false);
                this._initView();
            },
            _confirmValue: function() {
                this.$emit('confirm', this.$data.remarkValue);
                this._closePopover();
            }
        }
    });
}
module.exports = esChargeRemark