var esModal = function(Vue){
    var prefixCls = "es-modal"
    var template = '<div class="es-popup-dom" v-show="visible">\
            <div class="es-popup-mask"></div>\
            <div class="es-popup-wrap  vertical-center-modal">\
                <div :class="[prefixCls +  \'-content\']" :style="contentStyle">\
                    <div :class="[prefixCls + \'-close\']">\
                        <slot name="close">\
                            <icon class="iconfont icon-close" @click="close"></icon>\
                        </slot>\
                    </div>\
                    <div :class="[prefixCls + \'-head\']"><span>{{localTitle}}</span></div>\
                    <div :class="[prefixCls + \'-body\']"><slot></slot></div>\
                    <div :class="[prefixCls + \'-footer\']">\
                        <slot name="footer">\
                            <button type="button" class="btn btn-default negative" @click="cancel">{{localCancelText}}</button>\
                            <button type="button" class="btn btn-primary positive" @click="confirm">{{localConfirmText}}</button>\
                        </slot>\
                    </div>\
                </div>\
            </div>\
        </div>'
    Vue.component('es-modal',{
        componentName: 'es-modal',
        template: template,
        props: {
            value:{
                type: Boolean,
                default: false
            },
            width: {
                type: Number
            },
            className:{
                type: String
            },
            title:{
                type: String
            },
            cancelText: {
                type: String
            },
            confirmText: {
                type: String
            }
        },
        data: function () {
            return {
                isCancelShow: true,
                isConfirmShow: true,
                prefixCls: "es-popup",
                visible: this.value,
                contentStyle: {
                    width: this.width + 'px'
                }
            }
        },
        computed: {
            localTitle: function () {
                if(this.title == undefined){
                    return "鸭嘴兽"
                }else{
                    return this.title
                }
            },
            localCancelText: function () {
                if(this.cancelText == undefined){
                    return "取消"
                }else{
                    return this.cancelText
                }
            },
            localConfirmText: function () {
                if(this.confirmText == undefined){
                    return "确认"
                }else{
                    return this.confirmText
                }
            },
            wrapClass: function () {
                return [
                    '$(prefixCls)-wrap',
                    {
                        '$(prefixCls)-hidden': !this.wrapShow,
                        '$(this.className)': !!this.className
                    }
                ]
            }
        },
        methods: {
            escClose: function (e) {
                if (this.visible) {
                    if (e.keyCode === 27) {
                        this.close();
                    }
                }
            },
            close: function () {
                this.visible = false;
                this.$emit('input', false);
                this.$emit('on-cancel');
            },
            cancel: function () {
                this.close();
            },
            confirm: function () {
                // if (this.loading) {
                //     this.buttonLoading = true;
                // } else {
                //     this.visible = false;
                //     this.$emit('input', false);
                // }
                this.$emit('on-confirm');
            }
        },
        mounted:function () {
            // ESC close
            document.addEventListener('keydown', this.escClose);
        },
        beforeDestroy : function() {
            document.removeEventListener('keydown', this.escClose);
        },
        watch: {
            value: function (val) {
                this.visible = val;
            }
        }
    })
}
module.exports = esModal