<template>
  <div class="modal fade dk-modal" mode="out-in" :style="zIndex" :class="[{'in':fade},name]">
      <div ref="modalFull" class="modal-dialog modal-full" :style="viewStyle">
          <div class="modal-content">
              <div class="modal-header" v-if="isShowTitle" @mousedown="mousedownEvent" @mousemove="mousemoveEvent" @mouseup="mouseupEvent" @mouseout="mouseoutEvent">
                  <button type="button" class="close"  @click="hide" aria-label="Close" aria-hidden="true"><i class="iconfont icon-close"></i></button>
                  <h4 class="modal-title">{{title}}</h4>
              </div>
              <div class="modal-body">
                  <slot><div>内容</div></slot>
              </div>
              <div class="modal-footer">
                  <slot name="footer">
                      <button type="button" class="btn btn-default negative" @click="hide">关闭</button>
                  </slot>
              </div>
          </div>
      </div>
      <div class="modal-backdrop fade" v-if="!hideBackdrop" :class="{'in':fade}"></div>
</div>
</template>

<script>
export default {
  name: "hello",
  data() {
    return {
      moveInfo: {
        isStart: false,
        initClinetX: 0,
        initClinetY: 0,
        left: 0,
        top: 0
      },
      contentStyle: {}
    };
  },
  props: {
    isShowTitle:{
      type:Boolean,
      default:true
    },
    name:{
        type: String,
        default: ''
    },
    title: {
      type: String,
      default: "标题"
    },
    fade: {
      type: Boolean,
      default: false
    },
    styleObjs: {
      type: Object,
      default(){
        return {}
      }
    },
    hideBackdrop: {
      type: Boolean,
      default: false
    },
    zindex: {
      type: Number,
      default: 0
    },
    canDrag: {
      type: Boolean,
      default: false
    },
    bodyClientWidth:{
      type: Number,
      default: 0
    }
  },
  computed: {
    zIndex: function() {
      return {
        "z-index": 99999 + this.zindex + "!important"
      };
    },
    viewStyle: function() {
    //   var data = JSON.parse(JSON.stringify(this.styleObjs));
    var data = {};
      for (var i in this.contentStyle) {
        data[i] = this.contentStyle[i];
      }
      for (var i in this.styleObjs) {
        data[i] = this.styleObjs[i];
      }
      return data;
    }
  },
  methods: {
    hide: function() {
      console.log('hideCloseChange',this.hideCloseChange)
      this.fade = false;
      this.$emit("change", this.fade);
    },
    mousedownEvent: function(event) {
      if (!this.canDrag) {
        return;
      }
      this.moveInfo.isStart = true;
      this.moveInfo.initClinetX = event.clientX;
      this.moveInfo.initClinetY = event.clientY;
      event.preventDefault();
    },
    mousemoveEvent: function(event) {
      if (!this.canDrag) {
        return;
      }
      if (!this.moveInfo.isStart) return;
      var data = _.cloneDeep(this.contentStyle),
        moveLeft = event.clientX - this.moveInfo.initClinetX,
        moveTop = event.clientY - this.moveInfo.initClinetY;
      this.moveInfo.left += moveLeft;
      this.moveInfo.top += moveTop;
      if (this.moveInfo.left < 0) this.moveInfo.left = 0;
      if (this.moveInfo.top < 0) this.moveInfo.top = 0;
      data["marginLeft"] = this.moveInfo.left + "px";
      data["marginTop"] = this.moveInfo.top + "px";
      this.contentStyle = data;
      this.moveInfo.initClinetX = event.clientX;
      this.moveInfo.initClinetY = event.clientY;
    },
    mouseupEvent: function() {
      if (!this.canDrag) {
        return;
      }
      this.moveInfo.isStart = false;
    },
    mouseoutEvent: function() {
      if (!this.canDrag) {
        return;
      }
      this.moveInfo.isStart = false;
    },
    initStyle: function() {
      var clientWidth = this.$refs.modalFull.clientWidth,
        bodyClientWidth = document.getElementsByTagName("body")[0].clientWidth;
      if (window.screen.width <= 768) {
        bodyClientWidth = window.screen.width;
      }
      if(this.bodyClientWidth){
        bodyClientWidth=this.bodyClientWidth
      }
      var left = (bodyClientWidth - clientWidth) / 2;
      this.moveInfo.left = left;
      this.contentStyle = {
        marginLeft: left + "px"
      };
    }
  },
  watch: {
    fade: function() {
      console.log('hideCloseChange',this.hideCloseChange)
      if (!this.fade) return;
      this.$nextTick(function() {
        this.initStyle();
      });
    }
  }
};
</script>



