<template>
  <div class="input-group" v-if="isRouteStrategy">
    <label class="control-label" :style="{width: labelWidth + 'px'}">受理组:</label>
    <div>
      <select
        class="form-control"
        v-model="innerValue"
      >
        <option :value="item.id" v-for="item in filterOptionList" >
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { fetchDriverServiceGroupByLevel } from "../api/driverChat";

const map = {};
export default {
  props: {
    value: {
      default: "",
      type: String,
    },
    filterValue: {
      default: null,
      type: String,
    },
    labelWidth: {
      default: "80",
      type: String,
    }
  },
  data() {
    return {
      hasQequested: false,
      dispatcherGroupList: [],
      isRouteStrategy: window.__ACCEPT_GROUP_STRATEGY__ === "postingDispatchGroupRouteStrategyImpl",
    };
  },
  computed: {
    filterOptionList() {
      if (this.filterValue === "") {
        this.$emit('input', "")
        this.$forceUpdate()
        return [
          {
            id: "",
            name: "全部",
          }
        ]
      }
      let list
      if (!this.filterValue || (Array.isArray(this.filterValue) && !this.filterValue.length)) {
        list = this.dispatcherGroupList
      } else {
        let includeList = Array.isArray(this.filterValue) ? this.filterValue : [ this.filterValue ]
        list = this.dispatcherGroupList.filter(v => includeList.includes(v.portId) || !v.id)
      }
      return list
    },
    innerValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  watch: {
    filterOptionList() {
      if (!this.isRouteStrategy) {
        return
      }
      this.emitInput()
    }
  },
  methods: {
    emitInput() {
      if (this.hasQequested) {
        const has = this.filterOptionList.find(v => v.id == this.value)
        if (!has) {
          this.$emit('input', "")
        }
        return
      }
      setTimeout(() => {
        this.emitInput()
      }, 500)
    },
    // pure
    async fetchData(type) {
      if (map[type]) {
        return Promise.resolve(map[type]);
      }
      const res = await fetchDriverServiceGroupByLevel({
        level: 2
      })
      const list = _.get(res, "data.data", []).map(v => {
        return {
          id: String(v.id),
          name: v.name,
          portId: v.portId
        }
      });
      list.unshift({
        id: "",
        name: "全部", 
      });
      map[type] = list;
      return list;
    }
  },
  created() {
    if (this.isRouteStrategy) {
      this.fetchData("dk-accept-group-select").then(list => {
        this.dispatcherGroupList = list
        this.hasQequested = true
      });
    }
  },
};
</script>

<style>
</style>
